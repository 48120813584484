<template>
  <v-container fluid>
    <v-card flat style="height: 87vh; -webkit-overflow-scrolling:touch;" class="overflow-y-auto" color="#f1f1f1">
      <v-toolbar color="grey darken-4 d-none d-md-flex" dark extended flat>
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      </v-toolbar>

      <v-card class="mx-auto" max-width="1100" style="margin-top: -64px;" :loading="loading">
        <v-toolbar flat>
          <v-toolbar-title class="black--text">PAGAR PEDIDO</v-toolbar-title>

          <v-spacer></v-spacer>

          <!--<v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>-->
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" class="subtitle-2 pt-0 pb-1">ELIGE UNA TARJETA DE CRÉDITO</v-col>
                <v-col cols="12">

                    <v-list
                    subheader
                    two-line
                    flat
                    >
                    
                    <v-list-item-group
                        v-model="creditCard"
                        v-if="creditCards"
                    >
                        <template v-for="(item, i) in creditCards">

                        <v-list-item :key="i">
                        <template v-slot:default="{ active, }">
                            <v-list-item-action>
                            <v-checkbox
                                :input-value="active"
                                color="primary"
                            ></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>{{item.attributes.brand}} **** {{item.attributes.last4}}</v-list-item-title>
                                <v-list-item-subtitle>{{item.attributes.name}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        </v-list-item>

                        </template>

                    </v-list-item-group>
                    </v-list>

                    <v-btn class="mb-1" @click="modalAddCreditCard = true" outlined>
                        <v-icon left>mdi-pencil</v-icon>Agregar tarjeta de crédito
                    </v-btn>

                </v-col>

              </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-simple-table dense class="pl-3 mb-3" v-if="order">
                    <template v-slot:default>
                    <tbody>
                        <tr>
                        <td class="text-left pl-0"><strong>SUBTOTAL</strong></td>
                        <td class="text-right">{{ order.attributes.total - order.attributes.deliveryCost | currency }}</td>
                        </tr>
                        
                        <tr>
                        <td class="text-left pl-0"><strong>DOMICILIO</strong></td>
                        <td class="text-right">{{ order.attributes.deliveryCost | currency}}</td>
                        </tr>

                        <tr v-if="order.attributes.tip">
                        <td class="text-left pl-0"><strong> {{ $store.state.zone.config ? $store.state.zone.config.tip.label : 'PROPINA' }} </strong></td>
                        <td class="text-right">{{ order.attributes.tip | currency}}</td>
                        </tr>

                        <tr v-if="order.attributes.discount">
                        <td class="text-left pl-0"><strong>DESCUENTO</strong></td>
                        <td class="text-right">-{{ order.attributes.discount | currency}}</td>
                        </tr>
                        
                        <tr>
                        <td class="text-left pl-0 subtitle-1"><strong>TOTAL</strong></td>
                        <td class="text-right subtitle-1"><strong>{{ order.attributes.total | currency  }}</strong></td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                <v-btn
                    class="mt-2"
                    color="success"
                    block
                    @click="payOrder()"
                    >
                    <v-icon left>mdi-check-circle</v-icon>
                    Ir a pagar
                </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      v-model="modalAddCreditCard"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
         
        </v-card-title>

        <v-card-text>
            <vue-credit-card 
                :preview-enabled="true" 
                v-model="creditCardForm" >
            </vue-credit-card>

            <v-text-field
                class="mt-3"
                v-model.number="nameCard"
                type="text"
                label="Nombre personalizado (opcional)"
                placeholder="Mi tarjeta, Tarjeta de papá"
                outlined
                hide-details
            ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="modalAddCreditCard = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="saveCard()"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import Parse from 'parse'
import VueCreditCard from '@fracto/vue-credit-card';

export default {

    components: {
        'vue-credit-card': VueCreditCard
    },
    
    data() {
        return {
            order: '',
            loading: false,
            modalConfirmPay: false,
            creditCard: '',
            creditCards: '',
            creditCardForm: {},
            nameCard: '',
            modalAddCreditCard: false

        }
    },

    mounted() {
        this.init();
        this.test()
        VueCreditCard.i18n.locale = 'es'
    },

    methods: {
        async init(){
            this.loading = true

            let params = {
                id: this.$route.params.order_id
            }
            console.log(params);
            try {
                let order = await Parse.Cloud.run("getOrderForUser", params);
                this.order = order
                this.getCreditCards()
                this.loading = false

            } catch (error) {
                this.$snotify.error(`${error}`, "Orden", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
            }
        },

        async test(){
            let params = {
                amount: 2000
            }
            try {
                let result = await Parse.Cloud.run("paymentSheet", params);
                console.log(result);

            } catch (error) {
                this.$snotify.error(`${error}`, "paymentSheet", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
            }
        },
        

        async getCreditCards(){
            try {
                let creditCards = await Parse.Cloud.run("getTokensCardStripe");
                this.creditCards = creditCards
                this.loading = false

            } catch (error) {
                this.$snotify.error(`${error}`, "Credit Cards List", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
            }
        },

        async saveCard(){

            if (!this.creditCardForm || !this.creditCardForm.number || !this.creditCardForm.holder || !this.creditCardForm.month || !this.creditCardForm.year || !this.creditCardForm.cvv) {
                this.$snotify.error(`Compruebe todos los campos`, "Credit Card", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
                return;
            }

            let params = {
                number: this.creditCardForm.number,
                expMonth: this.creditCardForm.month,
                expYear: this.creditCardForm.year,
                cvc:this.creditCardForm.cvv,
                holder: this.creditCardForm.holder,
                name: this.nameCard,
                preferred: true
            }

            try {

                await Parse.Cloud.run("addTokenCardStripe", params);
                this.init()
                this.modalAddCreditCard = false
                this.$snotify.success(`Se ha agregado correctamente`, "Credit Card", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
                
            } catch (error) {
                this.$snotify.error(`${error}`, "Credit Card", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
            }
        },

        async payOrder(){
            if (this.creditCards.length == 0) {
                this.$snotify.error(`No tienes tarjetas de crédio`, "Credit Card", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
                return;
            }

            let creditCard = this.creditCards[this.creditCard]
            if (!creditCard) {
                this.$snotify.error(`Seleccione una tarjeta de crédito`, "Credit Card", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
                return;
            }

            try {
                let params = {
                    id: this.$route.params.order_id,
                    cardId: creditCard.id
                }
                await Parse.Cloud.run("payOrderStripe", params);

                this.$snotify.success(`Nueva orden creada correctamente`, "Nueva Orden", {
                    timeout: 4000,
                    preventDuplicates: true,
                });

                this.$router.push('/orders')

            } catch (error) {
                this.$snotify.error(`${error}`, "Payment", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
            }
            
        }
    },
}
</script>

<style lang="css">
    .vcc-credit-card-cvv{
        height: auto !important;
        width: auto !important;
    }
</style>